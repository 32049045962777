"use client";

import classNames from "classnames/bind";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { BlocksPhotoTiles } from "../../../__generated__/contentful-types";
import { notNull } from "../../../helpers/notNull";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./photo-tiles.module.css";

const cx = classNames.bind(styles);

const VERTICAL_CARD_INDEX = 2;
const MOODBOARD_TILE_LIMIT = 3;
const SCROLLANIMATION_THRESHOLD = 0;

export const PhotoTiles = ({ block }: { block: BlocksPhotoTiles }) => {
  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: SCROLLANIMATION_THRESHOLD,
  });

  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("main")}>
          <div className={cx("intro")}>
            <h2 className={cx("intro-title")}>{block.title}</h2>
            <p className={cx("intro-text")}>{block.text}</p>
          </div>
          <div
            className={cx("tiles", {
              "tiles--with-animation": hasIntersected,
            })}
            aria-hidden="true"
            ref={ref}
          >
            {block.tilesCollection &&
              block.tilesCollection.items
                .filter(notNull)
                .map((tile, tileIndex) => (
                  <div
                    key={tileIndex}
                    className={cx(
                      "tile",
                      tileIndex % MOODBOARD_TILE_LIMIT === VERTICAL_CARD_INDEX
                        ? "tile--vertical"
                        : "tile--horizontal",
                    )}
                    aria-hidden="true"
                  >
                    <div className={cx("tile-inner")}>
                      {tile.url && (
                        <img
                          loading={imageLoading}
                          srcSet={responsiveImageHelper.createContentfulSrcSet(
                            tile.url,
                          )}
                          sizes="(min-width: 1000px) min(510px, 42vw), (min-width: 650px) 63vw, 67vw"
                          className={cx("tile-image")}
                          alt={tile.description ?? ""}
                        />
                      )}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};
